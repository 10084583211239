var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    staticClass: "merForm",
    attrs: {
      "name": "wizzpayForm"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    staticClass: "input",
    attrs: {
      "id": "GOODSNAME",
      "placeholder": "상품명",
      "name": "GOODSNAME",
      "size": "20"
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    staticClass: "input",
    attrs: {
      "id": "AMT",
      "placeholder": "상품금액",
      "size": "20"
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.redirectURL,
      expression: "redirectURL"
    }],
    staticClass: "input",
    attrs: {
      "id": "RESULTURL",
      "placeholder": "resultURL",
      "size": "50"
    },
    domProps: {
      "value": _vm.redirectURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.redirectURL = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.responseURL,
      expression: "responseURL"
    }],
    staticClass: "input",
    attrs: {
      "id": "NOTIURL",
      "placeholder": "notiURL",
      "size": "50"
    },
    domProps: {
      "value": _vm.responseURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.responseURL = $event.target.value;
      }
    }
  }), _c('input', {
    staticClass: "input",
    attrs: {
      "id": "BYPASSVALUE",
      "placeholder": "bypassvalue",
      "size": "50",
      "value": "field1=abc;field2=def;"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    staticClass: "input",
    attrs: {
      "id": "BUYERNAME",
      "placeholder": "구매자명",
      "size": "20"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }