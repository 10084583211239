<template>
<form ref="form" class="merForm" name="wizzpayForm">
    <input id="GOODSNAME" placeholder="상품명" name="GOODSNAME" size="20" class="input" v-model="orderName">
    <input id="AMT" placeholder="상품금액" size="20" class="input" v-model="paymentAmount">
    <input id="RESULTURL" placeholder="resultURL" size="50" class="input" v-model="redirectURL">
    <input id="NOTIURL" placeholder="notiURL" size="50" class="input" v-model="responseURL">
    <input id="BYPASSVALUE" placeholder="bypassvalue" size="50" class="input" value="field1=abc;field2=def;">
    <input id="BUYERNAME" placeholder="구매자명" class="input" size="20" v-model="buyerName">
</form>
</template>

<script>
export default {
    data() {
        return {
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderNo,
            paymentAmount: this.$route.query.paymentAmount,
            buyerName: this.$route.query.buyerName,
            
            responseURL: null,

            RESULT_DATA: null
        }
    },
    mounted() {
        console.log({ query: this.$route.query });

        window.wizzpayComplete = (payload) => {
            window?.parent?.wizzpayComplete(payload);
            window?.opener?.wizzpayComplete(payload);
        };

        window.addEventListener("message", (e) => {
            console.log({ data: e.data });
			if(e.data.RETURNCODE == "0000" || e.data.RETURNCODE == "3001") {
				this.RESULT_DATA = e.data;
				if(this.$refs.form.RESULTURL != undefined && this.$refs.form.RESULTURL.value != "" && this.$refs.form.RESULTURL.value.length > 0) {
					let resultForm = document.createElement("form");
					resultForm.action = e.data.RESULT_URL;
                    resultForm.method = "post";

					resultForm.appendChild(this.createHiddenInputDOM("RETURNCODE", e.data.RETURNCODE));
					resultForm.appendChild(this.createHiddenInputDOM("RETURNMSG", e.data.RETURNMSG));
					resultForm.appendChild(this.createHiddenInputDOM("TID", e.data.TID));
					resultForm.appendChild(this.createHiddenInputDOM("CARDAUTHNO", e.data.CARDAUTHNO));
					resultForm.appendChild(this.createHiddenInputDOM("ORDERID", e.data.ORDERID));
					resultForm.appendChild(this.createHiddenInputDOM("GOODSNAME", e.data.GOODSNAME));
					resultForm.appendChild(this.createHiddenInputDOM("AMT", e.data.AMT));
					resultForm.appendChild(this.createHiddenInputDOM("TRANDATE", e.data.TRANDATE));
					resultForm.appendChild(this.createHiddenInputDOM("CARDCODE", e.data.CARDCODE));
					resultForm.appendChild(this.createHiddenInputDOM("CARDNAME", e.data.CARDNAME));
					resultForm.appendChild(this.createHiddenInputDOM("CARDNO", e.data.CARDNO));
					resultForm.appendChild(this.createHiddenInputDOM("QUOTA", e.data.QUOTA));
					resultForm.appendChild(this.createHiddenInputDOM("BYPASSVALUE", e.data.BYPASSVALUE));

					document.body.appendChild(resultForm);
					resultForm.submit();
				} else if(typeof(this.RESULT_FUNCTION) == 'function') {
					this.RESULT_FUNCTION();
				} else {
					this.errorLog("'RESULTURL' and 'RESULT_FUNCTION' invalid value");
				}
		    } else if(e.data.resultCode === "cancel") {
		    	// alert("결제가 취소 되었습니다.");
		    }
		});

        this.auth();
    },
    beforeDestroy() {
        window.wizzpayComplete = undefined;
        window.auth = undefined;
    },
    methods: {
        async auth() {
            var { gateway, mid, encrypted, responseURL, redirectURL } = await this.$http.post(`/api/plugins/wizzpay/auth`, this.$data).then(res => res.data);

            this.responseURL = responseURL;
            this.redirectURL = redirectURL;

            this.$nextTick(() => {
                var form = this.$refs.form;

                form.appendChild(this.createHiddenInputDOM("MID", mid));
                form.appendChild(this.createHiddenInputDOM("DATA", encrypted));
                form.appendChild(this.createHiddenInputDOM("BLOCK_CARD_COMPANIES", ['']));

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    // 모바일 환경일때 처리
                }
                else{
                    // PC 환경일때 처리
                    var width = 710;
                    var height = 510;
                    var leftpos = screen.width / 2 - (width / 2);
                    var toppos = screen.height / 2 - (height / 2);
                    var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;

                    window.open('about:blank', "wizzPayPopup", feature);

                    form.target = "wizzPayPopup";
                }
                
                form.action = gateway;
                form.method = "post";
                form.submit();
            });
        },
        createHiddenInputDOM(name, value) {
            let inputDom = document.createElement("input");
            inputDom.setAttribute("type", "hidden");
            inputDom.setAttribute("name", name);
            inputDom.setAttribute("value", value);

            return inputDom;
        }
    },
};
</script>
